
export default {
  name: "Home",
  mounted(): void {
    try {
      console.log("Mounting");
    } catch (error) {
      console.error("Error in mounted");
    }
  },
};
