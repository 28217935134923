
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProfileImage",
  methods: {
    greet: () => {
      console.log("Routed to about page");
    },
  },
});
