
import { defineComponent } from "vue";
import { SiteLinks } from "../constants/site-links";
import ProfileImage from "@/components/ProfileImage.vue";

export default defineComponent({
  name: "Home",
  components: {
    ProfileImage,
  },
  data: () => {
    return {
      siteLinks: SiteLinks,
    };
  },
  methods: {
    greet: () => {
      console.log("Routed to about page");
    },
  },
});
