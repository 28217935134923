import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/avatar.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    class: "profile-pic",
    alt: "profile-pic",
    src: _imports_0,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.greet && _ctx.greet(...args)))
  }))
}